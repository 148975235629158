import { DateTime } from "luxon";

import { QueryString_stringify } from "common/helpers/QueryStringHelper";
import { Linking_openURL } from "./LinkingHelper";
import { AppDispatch } from "common/redux";
const format = (date: DateTime) => {
  return date.toFormat("yyyyMMdd") + "T" + date.toFormat("HHmmss");
};

const URL = "https://calendar.google.com/calendar/render?";

interface IProps {
  title: string;
  description?: string;
  location?: string;
  startDate: DateTime;
  endDate?: DateTime;
  dispatch: AppDispatch;
  onFinish?: () => void;
}

const CalendarHelper_addEvent = ({
  title,
  description,
  location,
  startDate,
  endDate,
  dispatch,
  onFinish
}: IProps) => {
  const startDateString = format(startDate);
  const endDateString = format(
    endDate ? endDate : startDate.plus({ hours: 1 })
  );

  const params = {
    action: "TEMPLATE",
    text: title,
    details: description,
    dates: `${startDateString}/${endDateString}`,
    location: location
  };
  const queryString = QueryString_stringify(params);
  Linking_openURL(URL + queryString, dispatch);
  onFinish && onFinish();
};

export { CalendarHelper_addEvent };
