import { useMemo } from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";
import { LightenDarkenColor } from "lighten-darken-color";

import { prettyStatusString } from "common/helpers/helpers";

import Text from "./Text";
import useGetCurrentColorScheme from "../../hooks/useGetCurrentColorScheme";

interface IProps {
  text: string;
  color: string;
}

const StatusBadge = ({ text, color }: IProps) => {
  const styles = useStyles();

  const status = useMemo(() => {
    return prettyStatusString(text);
  }, []);
  const colorScheme = useGetCurrentColorScheme();

  const lightenDarkenColorChange = colorScheme === "dark" ? -80 : 80;
  const backgroundColor = useMemo(
    () => LightenDarkenColor(color, lightenDarkenColorChange),
    [color]
  );

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <View style={[styles.circle, { backgroundColor: color }]} />
      <Text style={[{ color }]}>{status}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flexDirection: "row",
      borderRadius: 25,
      paddingHorizontal: 10,
      paddingVertical: 5,
      alignItems: "center",
      gap: 5
    },
    circle: {
      width: 4,
      height: 4,
      borderRadius: 2
    }
  };
});

export default StatusBadge;
