import { makeStyles } from "@rneui/themed";
import Fonts from "../../../constants/Fonts";

const useTextStyles = makeStyles((theme) => {
  return {
    textAlignCenter: {
      textAlign: "center"
    },

    // Color styles
    colorError: {
      color: theme.colors.error
    },
    colorTealAAA: {
      color: theme.colors.tealAAA
    },
    colorTealBlue: {
      color: theme.colors.tealBlue
    },
    colorDarkGreyBlue: {
      color: theme.colors.darkGreyBlue
    },
    colorGreyBlue: {
      color: theme.colors.greyBlue
    },
    colorDarkBlue: {
      color: theme.colors.darkBlue
    },
    colorGrey0: {
      color: theme.colors.grey0
    },
    colorWhite: {
      color: theme.colors.white
    },

    // Font styles
    largeTitle: {
      fontSize: 38,
      fontFamily: Fonts.Inter[700],
      fontWeight: "700"
    },
    h1Style: {
      fontSize: 34,
      fontFamily: Fonts.Inter[700],
      fontWeight: "700"
    },
    h2Style: {
      fontSize: 30,
      fontFamily: Fonts.Inter[700],
      fontWeight: "700"
    },
    h3Style: {
      fontSize: 26,
      fontFamily: Fonts.Inter[700],
      fontWeight: "700"
    },
    h4Style: {
      fontSize: 22,
      fontFamily: Fonts.Inter[700],
      fontWeight: "700"
    },
    bodyLarge: {
      fontSize: 20,
      fontFamily: Fonts.Inter[400],
      fontWeight: "400"
    },
    body: {
      fontSize: 18,
      fontFamily: Fonts.Inter[400],
      fontWeight: "400"
    },
    bodySmall: {
      fontSize: 16,
      fontFamily: Fonts.Inter[400],
      fontWeight: "400"
    },
    capsLarge: {
      fontSize: 18,
      fontFamily: Fonts.Inter[600],
      fontWeight: "600"
    },
    caps: {
      fontSize: 16,
      fontFamily: Fonts.Inter[600],
      fontWeight: "600"
    },
    capsSmall: {
      fontSize: 14,
      fontFamily: Fonts.Inter[600],
      fontWeight: "600"
    },
    link: {
      fontSize: 18,
      fontFamily: Fonts.Inter[500],
      fontWeight: "500",
      textDecorationLine: "underline",
      color: theme.colors.tealBlue
    },
    linkLarge: {
      fontSize: 14,
      fontFamily: Fonts.Inter[600],
      fontWeight: "600",
      textDecorationLine: "underline",
      color: theme.colors.tealBlue
    },
    italic: {
      fontSize: 14,
      fontFamily: Fonts.Inter["italic"],
      fontWeight: "400",
      fontStyle: "italic"
    }
  };
});

export default useTextStyles;
