import { useEffect, useMemo, useState } from "react";
import { Platform, View } from "react-native";
import { DateTime } from "luxon";

import Feather from "react-native-vector-icons/Feather";

import Text from "../Text";
import { Divider, makeStyles, useTheme } from "@rneui/themed";
import Spacing from "../Spacing";
import Button from "../Button";
import useViewStyles from "../styles/useViewStyles";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../../navigation/DashboardStackNavigator";

import useTextStyles from "../styles/useTextStyles";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import LocalizedStrings from "../../../helpers/LocalizedStrings";
import { CalendarHelper_addEvent } from "../../../helpers/CalendarHelper";
import { useAppDispatch } from "common/redux";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";

interface IProps {
  appointment: CalendarEventResponseType;
  canConfirmOrReschedule: boolean;
}
type ScreenProp = NavigationProp<DashboardStackParamList>;

const INCOMING_MINUTES = 15;

const AppointmentCard = ({ appointment, canConfirmOrReschedule }: IProps) => {
  const navigation = useNavigation<ScreenProp>();

  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  const { data: member } = useGetAuthenticatedMember();

  const dateStart = DateTime.fromISO(appointment?.startdate);
  const dateEnd = DateTime.fromISO(appointment?.enddate);

  const doctorName = getNameOrUsername(appointment.staff);

  const appointmentDateRange =
    dateStart.toFormat("hh:mm a") + " - " + dateEnd.toFormat("hh:mm a");

  const isStarted = dateStart < DateTime.now();

  const [appointmentIncoming, setAppointmentIncoming] =
    useState<boolean>(false);

  useEffect(() => {
    const now = DateTime.now();
    const { seconds: remainingSeconds } = dateStart.diff(now, "seconds");
    const remainingMinutes = remainingSeconds / 60;

    if (remainingMinutes > INCOMING_MINUTES) {
      // This is breaking JEST, but we need this feature.
      // https://copilotiq.slack.com/archives/C04LKJ7DZ34/p1733496927821819
      // "Fixed" by not mocking data on mockGetPatientCalendarEvents.json
      const timeout = setTimeout(
        () => {
          if (remainingMinutes <= INCOMING_MINUTES)
            setAppointmentIncoming(true);
        },
        (remainingMinutes - INCOMING_MINUTES) * 60 * 1000
      );
      return () => {
        clearTimeout(timeout);
      };
    } else setAppointmentIncoming(true);
  }, [dateStart]);

  const isSameDay = useMemo(() => {
    return DateTime.now().toISODate() === dateStart.toISODate();
  }, [dateStart]);

  const isConfirmed = useMemo(() => {
    if (isFalsy(member)) return;

    const attendee = appointment.attendees.find(
      (item) => item.attendee_id === member.patient.patient_id
    );
    return attendee?.confirmed === true;
  }, [appointment, member]);

  const handleAddToCalendar = () => {
    setTimeout(
      () =>
        CalendarHelper_addEvent({
          title:
            LocalizedStrings.screens.bookAppointment.addToCalendarTitle.replace(
              "{{DOCTOR_NAME}}",
              doctorName
            ),
          description:
            LocalizedStrings.screens.bookAppointment.addToCalendarDescription.replace(
              "{{DOCTOR_NAME}}",
              doctorName
            ),
          startDate: dateStart,
          endDate: dateEnd,
          dispatch
        }),
      1000
    );
  };

  return (
    <View
      style={[
        viewStyles.cardContainer,
        appointmentIncoming && styles.containerSecondary,
        styles.flexGrow1
      ]}
    >
      <View style={[styles.row, styles.center]}>
        <View style={styles.center}>
          <Text h2 style={textStyles.colorGreyBlue}>
            {dateStart.toFormat("dd")}
          </Text>
          <Text h2 style={textStyles.colorGreyBlue}>
            {dateStart.toFormat("MMM")}
          </Text>
        </View>
        <Divider orientation="vertical" color={theme.colors.greyBlue} />
        <View style={{ flex: 1 }}>
          <Text caps style={textStyles.colorGreyBlue}>
            {appointmentDateRange}
          </Text>
          <Text body style={textStyles.colorGreyBlue}>
            {doctorName}
          </Text>
        </View>
      </View>

      {(canConfirmOrReschedule || isConfirmed) && <Spacing vertical={3} />}

      {isConfirmed && !isStarted && (
        <View style={styles.iconContainer}>
          <Text
            caps
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myAppointments.confirmedHeader}
          </Text>
          <Spacing vertical={1} />
          <Text
            bodySmall
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myAppointments.confirmedContent}
          </Text>

          <Feather
            style={styles.confirmedIcon}
            name="check-circle"
            size={20}
            color={theme.colors.darkGreyBlue}
          />
        </View>
      )}

      {isConfirmed && isStarted && (
        <View style={styles.iconContainer}>
          <Text
            caps
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myAppointments.startingSoonHeader}
          </Text>
          <Spacing vertical={1} />
          <Text
            bodySmall
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myAppointments.startingSoonContent}
          </Text>

          <Feather
            style={styles.confirmedIcon}
            name="heart"
            size={20}
            color={theme.colors.darkGreyBlue}
          />
        </View>
      )}

      {canConfirmOrReschedule && (
        <View>
          <>
            {!isConfirmed && (
              <Button
                containerStyle={styles.flex1}
                type={"solid"}
                title={"Confirm attendance"}
                onPress={() => {
                  navigation.navigate("ConfirmAppointment", {
                    appointment_id: appointment.event_id
                  });
                }}
              />
            )}

            {isFalsy(isSameDay) &&
              appointment.appointment_type ===
                AppointmentTypeEnum.NURSE_FOLLOWUP && (
                <>
                  <Spacing vertical={2} />
                  <Button
                    containerStyle={styles.flex1}
                    buttonStyle={{
                      backgroundColor: theme.colors.white,
                      marginBottom: Platform.OS === "web" ? 5 : 0
                    }}
                    type="outline"
                    title={"Reschedule"}
                    onPress={() =>
                      navigation.navigate("BookAppointment", {
                        event_id: appointment.event_id,
                        isReschedule: true
                      })
                    }
                  />
                </>
              )}
          </>
        </View>
      )}
      {isConfirmed && (
        <>
          <Spacing vertical={2} />
          <Button
            type="clear"
            onPress={handleAddToCalendar}
            title={LocalizedStrings.screens.confirmAppointment.addToCalendar}
          />
        </>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    containerSecondary: {
      backgroundColor: theme.colors.secondary,
      borderColor: "transparent"
    },
    iconContainer: {
      maxWidth: 300,
      alignSelf: "center"
    },
    center: {
      alignItems: "center"
    },
    row: {
      flexDirection: "row",
      gap: 10
    },
    flex1: {
      flex: 1
    },
    flexGrow1: {
      flexGrow: 1
    },
    confirmedIcon: {
      position: "absolute"
    }
  };
});

export default AppointmentCard;
