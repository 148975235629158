import React, { useMemo } from "react";
import { View } from "react-native";
import { DateTime } from "luxon";

import Spacing from "../Spacing";
import HeaderIconText from "../../home/HeaderIconText";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../../navigation/DashboardStackNavigator";
import AppointmentCard from "./AppointmentCard";
import MemberType from "common/types/MemberType";
import { useGetPatientCalendarEventsQuery } from "common/services/CalendarService";
import ActivityIndicator from "../ActivityIndicator";
import LocalizedStrings from "../../../helpers/LocalizedStrings";
import Text from "../Text";
import useTextStyles from "../styles/useTextStyles";

type ScreenProp = NavigationProp<DashboardStackParamList>;

interface IProps {
  patient: MemberType;
}

const AppointmentBanner = ({ patient }: IProps) => {
  const navigation = useNavigation<ScreenProp>();

  const textStyles = useTextStyles();

  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const { data, isLoading: isDataLoading } = useGetPatientCalendarEventsQuery(
    {
      patient_id: patient?.patient.patient_id,
      startdate: now.startOf("day"),
      enddate: now.plus({ days: 7 }).endOf("day")
    },
    { skip: patient === undefined }
  );

  const { nextAppointment, upcomingAppointments } = useMemo(() => {
    if (!data || data.length === 0) return {};

    const filteredData = data.filter(
      (item) => DateTime.fromISO(item.enddate) > DateTime.now()
    );

    if (filteredData.length === 0) return {};

    return {
      nextAppointment: filteredData[0],
      upcomingAppointments: filteredData.slice(1)
    };
  }, [data]);

  const isLoading = patient === undefined || isDataLoading;

  return (
    <View>
      {nextAppointment && (
        <>
          <HeaderIconText text="Next Appointment" />
          <Spacing vertical={2} />
          <AppointmentCard
            appointment={nextAppointment}
            canConfirmOrReschedule
          />
        </>
      )}

      <Spacing vertical={4} />
      <HeaderIconText
        text="Upcoming"
        linkText="See All"
        onLinkPress={() => navigation.navigate("MyAppointments")}
      />

      {isLoading && <ActivityIndicator />}

      {upcomingAppointments
        ?.map<React.ReactNode>((appointment, index) => {
          return (
            <AppointmentCard
              key={appointment.event_id}
              appointment={appointment}
              canConfirmOrReschedule={index === 0}
            />
          );
        })
        ?.reduce(
          (prev, curr) => [
            prev,
            <Spacing key={"SEPARATOR_" + prev.toString()} vertical={2} />,
            curr
          ],
          []
        )}
      {isLoading === false && !nextAppointment && (
        <>
          <Spacing vertical={2} />
          <Text
            body
            style={[textStyles.colorGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myAppointments.noData}
          </Text>
        </>
      )}
    </View>
  );
};

export default AppointmentBanner;
